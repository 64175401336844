<template>
  <div class="table_wrapper">
    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :search="search"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Report Problems</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="openModal(item)"
              color="primary"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
          <span>Approve</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-toolbar-title>Detail</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <section class="p-5" style="flex: 3 0 0">
          <v-row v-if="detail">
            <v-col cols="3">
              <h6>Type</h6>
              <h5>{{ detail.category }}</h5>
            </v-col>
            <v-col cols="3">
              <h6>Member Name</h6>
              <h5>
                {{
                  detail.infos[0].member_name
                    ? detail.infos[0].member_name +
                      " " +
                      detail.infos[0].member_lastname
                    : "---"
                }}
              </h5>
            </v-col>
            <v-col cols="3">
              <h6>Subject</h6>
              <h5>{{ detail.infos[0].subject }}</h5>
            </v-col>
            <v-col cols="12">
              <h6>Message</h6>
              <h5>{{ detail.infos[0].message }}</h5>
            </v-col>
            <v-col cols="3">
              <h6>Created at</h6>
              <h5>{{ detail.created_at }}</h5>
            </v-col>
            <v-col cols="12" v-if="detail.infos[0].file">
              <h6>Files</h6>
              <h5>
                <v-img
                  max-height="500"
                  max-width="500"
                  :src="detail.infos[0].file"
                ></v-img>
              </h5>
            </v-col>
          </v-row>
        </section>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "ReportProblems",
  data() {
    return {
      options: {},
      search: "",
      loading: true,
      dialog: false,
      detail: null,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Report Type", value: "c2", sortable: false },
        { text: "Member", value: "c3", sortable: false },
        { text: "Phone", value: "c4", sortable: false },
        { text: "Email", value: "c5", sortable: false },
        { text: "Member", value: "c6", sortable: false },
        { text: "Subject", value: "c7", sortable: false },
        { text: "Factory & Customer", value: "c8", sortable: false },
        { text: "Messages", value: "c9", sortable: false },
        { text: "Files", value: "c10", sortable: false },
        { text: "Created At", value: "c11", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 5,
      itemsData: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    openModal: function(item) {
      this.dialog = true;
      this.detail = this.itemsData.find((c) => c.id == item.id);
    },
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    getDataFromApi() {
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item) => {
          const key = {
            c1: item.id,
            c2: item.category,
            c3:
              item.infos.length > 0 &&
              item.infos[0].member_name &&
              item.infos[0].member_lastname
                ? item.infos[0].member_name +
                  " " +
                  item.infos[0].member_lastname
                : "",
            c4: item.infos.length > 0 ? item.infos[0].member_phone : "",
            c5: item.infos.length > 0 ? item.infos[0].member_email : "",
            c6:
              item.infos.length > 0
                ? item.infos[0].is_buyer && item.infos[0].is_seller
                  ? "Buyer & Seller"
                  : item.infos[0].is_buyer
                  ? "Buyer"
                  : "Seller"
                : "",
            c7: item.infos.length > 0 ? item.infos[0].subject : "",
            c8: item.factory
              ? item.factory.company_name
              : item.customer
              ? item.customer.name
              : "",
            c9:
              item.infos.length > 0
                ? item.infos[0].message &&
                  item.infos[0].message.substr(0, 50) + "..."
                : "",
            c10:
              item.infos.length > 0
                ? item.infos[0].file && item.infos[0].file != null
                  ? "Yes"
                  : "No"
                : "",
            c11: item.created_at,
            actions: null,
            id: item.id,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    getDesserts() {
      const fields = [];

      const sort = [
        {
          dir: "desc",
          field: "id",
        },
      ];

      return axios
        .post(
          process.env.VUE_APP_API_URL +
            "reports/customer-reports/filter?page=" +
            this.page +
            "&per_page=" +
            this.perPage +
            "&api_token=" +
            this.token,
          {
            fields,
            sort: sort,
            type: "get",
          }
        )
        .then(function(response) {
          return response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
